/* VARIABLES */

$NGHTLVS_PINK: rgba(169, 53, 116, 255);
$BG_GREY: rgb(52, 52, 52);

/* MIXINS */

@mixin flex_center() {
    display: flex;
    justify-content: center;
    align-items: center;
}

@mixin flex_right {
    display: flex;
    justify-content: right;
    align-items: center;
}

/* Not mixins */

* {
    box-sizing: border-box;
    margin: 0;
    font-family: "Orbitron", sans-serif;
    list-style: none;
}

button {
    border: none;
    font-size: 20px;
    padding: 10px;
    background-color: $NGHTLVS_PINK;

    &:hover {
        background-color: black;
    }
}

a {
    text-decoration: none;
    color: black;

    &:hover {
        cursor: pointer;
        background-color: black;
        color: $NGHTLVS_PINK;
    }
}

.hero {
    @include flex_center();
    flex-direction: column;
    img {
        width: 70%;
    }
    
    margin-bottom: 2px;
}

h1 {
    color: $NGHTLVS_PINK;
}

body {
    background-image: url(../img/Background_YellowHalftone.webp);
    background-size: cover;
} 

.App {
    justify-content: center;
}


nav {
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    align-items: center;
    font-size: 20px;
    position: relative;
    padding-top: 5px;
    min-height: 10vh;

    a {
        text-decoration: none;
        color: $NGHTLVS_PINK;
        font-weight: 600;
        font-size: 30px;

        &visited {
            color: $NGHTLVS_PINK
        }
        
        &:hover {
            color: white;
            cursor: pointer;
        }
    }

    img {
        height: 10vh;
    }
}

.nghtlvsNav {
    width: 50%;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.login-register {
    display: flex;
    flex-direction: column;
    color: $NGHTLVS_PINK;
}

.FourOhFour, .coming-soon {
    @include flex_center();
    flex-direction: column;
    color: $NGHTLVS_PINK;
    padding-top: 10%;

    img {
        width: 10%;
    }
}

.gigs {
    @include flex_center();

    .songkick-credit {
        margin-top: 5px;
        padding-top: 5px;
        border-top: 1px solid $NGHTLVS_PINK ;
        display: flex;
        justify-content: center;

        img {
            border-radius: 4px;
            background-color: $NGHTLVS_PINK;
            width: 15%;
        }
    }
}

.gig-table {
    .bg-grey-table {
        background-color: rgba(rgb(27, 27, 27), 0.6)
    }

    th {
        padding: 0 30px 0 30px;
    }

    tr {
        color: $NGHTLVS_PINK;
        padding: 20px;
        margin: 20px;
        font-size: 40px;
        text-align: center;
    }

    td {
        padding: 20px;
        color: white;
        font-size: 20px;
        text-align: left;
    }
    
    .tickets {
        @include flex_center();
    }
}

.login-register a {
    font-size: 15px;
}

.HomePageContainer {
    @include flex_center();
    flex-direction: column;
    justify-content: space-around;
    margin-top: 10px;

    h1 {
        margin-bottom: 10px; 
    }
}

.genericCard {
    margin: 20px 10px 20px 10px;
    width: 100vh;
    height: 55vh;
    iframe {
        width: inherit;
        height: inherit;
    }
}

.video-responsive {
    margin: 0;
    overflow: hidden;
    position: relative;
    height: 0;

    iframe {
        position: absolute;
        margin-right: 10px;
    }
}

.registerContainer {
    @include flex_center();
    flex-direction: column;
}

.regCard {
    h1 {
        font-size: 25px;
        margin-bottom: 15px;
    }

    @include flex_center();
    text-align: center;
    flex-direction: column;
    margin: 3vh;
    padding: 10px 10px 10px 10px;
    background-color: rgba($BG_GREY, 0.5);
    width: 40vh;
    height: 43vh;
    align-items: center;

    .input-fields {
        input {
            text-align: center;
            margin-top: 5px;
            display: flex;
            padding: 10px;
            font-size: 20px;
            margin-bottom: 5px;
        }
    }
}

.termsAndConditions {
    width: 30vh;
    overflow-wrap: break-word;
    display: flex;
    flex-direction: row;
    color: $NGHTLVS_PINK;
    margin-top: 5px;
    font-size: 13px;

    input {
        margin-right: 10px; 
    }
}

.music-player {
    @include flex_center();
}

.songwhip {
    width: 100rem;
    height: 100rem;
    border: 1px black solid;
    border-radius: 1%;
}

.songwhip::-webkit-scrollbar {
    display: none;
}

footer {
    @include flex_center();
    margin: 50px;
    img {
        margin: 10px 10px 10px 10px;
    }
}
