/* VARIABLES */
/* MIXINS */
/* Not mixins */
* {
  box-sizing: border-box;
  margin: 0;
  font-family: "Orbitron", sans-serif;
  list-style: none;
}

button {
  border: none;
  font-size: 20px;
  padding: 10px;
  background-color: rgb(169, 53, 116);
}
button:hover {
  background-color: black;
}

a {
  text-decoration: none;
  color: black;
}
a:hover {
  cursor: pointer;
  background-color: black;
  color: rgb(169, 53, 116);
}

.hero {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 2px;
}
.hero img {
  width: 70%;
}

h1 {
  color: rgb(169, 53, 116);
}

body {
  background-image: url(../img/Background_YellowHalftone.webp);
  background-size: cover;
}

.App {
  justify-content: center;
}

nav {
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  align-items: center;
  font-size: 20px;
  position: relative;
  padding-top: 5px;
  min-height: 10vh;
}
nav a {
  text-decoration: none;
  color: rgb(169, 53, 116);
  font-weight: 600;
  font-size: 30px;
}
nav avisited {
  color: rgb(169, 53, 116);
}
nav a:hover {
  color: white;
  cursor: pointer;
}
nav img {
  height: 10vh;
}

.nghtlvsNav {
  width: 50%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.login-register {
  display: flex;
  flex-direction: column;
  color: rgb(169, 53, 116);
}

.FourOhFour, .coming-soon {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: rgb(169, 53, 116);
  padding-top: 10%;
}
.FourOhFour img, .coming-soon img {
  width: 10%;
}

.gigs {
  display: flex;
  justify-content: center;
  align-items: center;
}
.gigs .songkick-credit {
  margin-top: 5px;
  padding-top: 5px;
  border-top: 1px solid rgb(169, 53, 116);
  display: flex;
  justify-content: center;
}
.gigs .songkick-credit img {
  border-radius: 4px;
  background-color: rgb(169, 53, 116);
  width: 15%;
}

.gig-table .bg-grey-table {
  background-color: rgba(27, 27, 27, 0.6);
}
.gig-table th {
  padding: 0 30px 0 30px;
}
.gig-table tr {
  color: rgb(169, 53, 116);
  padding: 20px;
  margin: 20px;
  font-size: 40px;
  text-align: center;
}
.gig-table td {
  padding: 20px;
  color: white;
  font-size: 20px;
  text-align: left;
}
.gig-table .tickets {
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-register a {
  font-size: 15px;
}

.HomePageContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  justify-content: space-around;
  margin-top: 10px;
}
.HomePageContainer h1 {
  margin-bottom: 10px;
}

.genericCard {
  margin: 20px 10px 20px 10px;
  width: 100vh;
  height: 55vh;
}
.genericCard iframe {
  width: inherit;
  height: inherit;
}

.video-responsive {
  margin: 0;
  overflow: hidden;
  position: relative;
  height: 0;
}
.video-responsive iframe {
  position: absolute;
  margin-right: 10px;
}

.registerContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.regCard {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  margin: 3vh;
  padding: 10px 10px 10px 10px;
  background-color: rgba(52, 52, 52, 0.5);
  width: 40vh;
  height: 43vh;
  align-items: center;
}
.regCard h1 {
  font-size: 25px;
  margin-bottom: 15px;
}
.regCard .input-fields input {
  text-align: center;
  margin-top: 5px;
  display: flex;
  padding: 10px;
  font-size: 20px;
  margin-bottom: 5px;
}

.termsAndConditions {
  width: 30vh;
  overflow-wrap: break-word;
  display: flex;
  flex-direction: row;
  color: rgb(169, 53, 116);
  margin-top: 5px;
  font-size: 13px;
}
.termsAndConditions input {
  margin-right: 10px;
}

.music-player {
  display: flex;
  justify-content: center;
  align-items: center;
}

.songwhip {
  width: 100rem;
  height: 100rem;
  border: 1px black solid;
  border-radius: 1%;
}

.songwhip::-webkit-scrollbar {
  display: none;
}

footer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 50px;
}
footer img {
  margin: 10px 10px 10px 10px;
}

